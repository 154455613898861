<template>
    <div>
        <van-nav-bar title="订单详情" left-arrow :border="false" @click-left="onClickLeft" />
        <div class="logo">
            <div>轻松参与数字经济</div>
            <div>便捷享受</div>
            <div>数字资产价值增长</div>
            <div v-if="info.status == 1">还剩<span style="color: red">00:{{ countdown.minutes < 10 ?
            `0${countdown.minutes}` : countdown.minutes }}:{{ countdown.seconds < 10 ?
            `0${countdown.seconds}` : countdown.seconds }}</span>自动取消订单</div>
        </div>
        <div class="content">
            <div class="contentTitle">
                <span>订单详情</span>
                <div class="status">{{ statusType[info.status] }}</div>
            </div>
            <div class="contentList">
                <span>交易金额</span>
                <span>{{ info.money }} CNY</span>
            </div>
            <div class="contentList">
                <span>交易数量</span>
                <span>{{ info.amount }} USDT</span>
            </div>
            <div class="contentList">
                <span>单价</span>
                <span>{{ info.price }} CNY</span>
            </div>
            <div class="contentList">
                <span>交易对象</span>
                <span>{{ info.otherSide }}</span>
            </div>
            <div class="contentList">
                <span>下单时间</span>
                <span>{{ info.createTime }}</span>
            </div>
            <div class="contentList">
                <span>订单编号</span>
                <span>{{ info.orderSn }}</span>
            </div>
        </div>
        <div class="pay" v-if="info.status == 2 || info.status == 1">
            <div style="width: 82.1883vw;display: flex;align-items: center;">
                <img src="../../assets/img/icon_zhifubao.png" alt=""
                    style="width: 4.3613vw;height: 4.3613vw;margin-right: 2.5445vw;">
                <span>支付宝</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>支付宝账号</span>
                <span>{{ info.payInfo.alipay ? info.payInfo.alipay.aliNo : '用户暂未添加支付宝账户' }}</span>
            </div>
        </div>
        <div class="pay" v-if="info.status == 2 || info.status == 1">
            <div style="width: 82.1883vw;display: flex;align-items: center;">
                <img src="../../assets/img/icon_wechat.png" alt=""
                    style="width: 4.3613vw;height: 4.3613vw;margin-right: 2.5445vw;">
                <span>微信</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>微信账号</span>
                <span><span>{{ info.payInfo.wechatPay ? info.payInfo.wechatPay.wechat : '用户暂未添加微信账户'
                        }}</span></span>
            </div>
        </div>
        <div class="pay1" v-if="info.status == 2 || info.status == 1">
            <div style="width: 82.1883vw;display: flex;align-items: center;">
                <img src="../../assets/img/icon_yinlian.png" alt=""
                    style="width: 4.3613vw;height: 4.3613vw;margin-right: 2.5445vw;">
                <span>银联</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>收款人姓名</span>
                <span>{{ info.payInfo.realName }}</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>开户银行</span>
                <span>{{ info.payInfo.bankInfo ? info.payInfo.bankInfo.bank : '' }}</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>开户支行</span>
                <span>{{ info.payInfo.bankInfo ? info.payInfo.bankInfo.branch : '' }}</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>银行卡账号</span>
                <span>{{ info.payInfo.bankInfo ? info.payInfo.bankInfo.cardNo : '用户暂未添加银行卡账户' }}</span>
            </div>
        </div>
        <div class="pay1" v-if="info.status == 2 || info.status == 1">
            <div style="width: 82.1883vw;display: flex;align-items: center;">
                <img src="../../assets/img/icon_guoji.png" alt=""
                    style="width: 4.3613vw;height: 4.3613vw;margin-right: 2.5445vw;">
                <span>SWIFT国际付款</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>收款人姓名</span>
                <span>{{ info.payInfo.realName }}</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: #aaa">
                <span>收款人地址</span>
                <span>{{ info.payInfo.swift ? info.payInfo.swift.swiftAddress : '暂未添加收款地址' }}</span>
            </div>
            <div style="width: 82.1883vw;display: flex;align-items: center;justify-content: space-between;color: red">
                如果您已向对方付款，请务必点击【我已付款】，否则可能造成资金损失</div>
        </div>
        <div style="width: 82.1883vw;font-size: 3.3079vw;color: #aaa;margin: 0 auto;margin-top: 3.8168vw;">
            温馨提示：<br>
            1、您的汇款将直接进入对方账户，交易过程中数字资产将由平台托管保护<br>
            2、请在规定的时间内完成付款，并务必点击【我已成功付款】按钮，对方确认收款后，系统会将数字资产划转到您的账户
        </div>
        <div style="width: 100%;height: 20.3562vw;"></div>
        <div class="bottom" v-if="info.status == 1 || info.status == 2">
            <span style="color: #3861FB;margin-left: 40.7125vw;margin-right: 6.3613vw;" @click="cancelOrder"
                v-if="info.status == 1">取消订单</span>
            <div class="bottomBtn" v-if="info.status == 1" @click="payFinish">我已成功付款</div>
            <div class="bottomBtn" v-if="info.status == 2" style="margin-left: 66.1578vw" @click="goTell">点我申诉</div>
        </div>
    </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
    data() {
        return {
            info: {},
            statusType: {
                0: '已取消',
                1: '等待付款',
                2: '已付款',
                3: '交易完成',
                4: '申诉中'
            },
            countdown: {
                minutes: '',
                seconds: ''
            },
            intervalId: null
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        //获取详情
        async getInfo() {
            const res = await this.$http.post(this.host + '/otc/order/detail', { orderSn: this.$route.query.orderNumber })
            this.info = res.data.data
            if (this.info.status == 1) {
                this.calculateCountdown();
                // 每秒钟更新计时器
                this.intervalId = setInterval(() => {
                    this.calculateCountdown();
                }, 1000);
            }
        },
        //取消订单
        cancelOrder() {
            Dialog.confirm({
                title: '取消订单',
                message: '确定要取消该订单吗?',
            })
                .then(() => {
                    this.$http.post(this.host + '/otc/order/cancel', { orderSn: this.$route.query.orderNumber }).then(res => {
                        if (res.data.code == 500) {
                            this.$toast(res.data.message)
                        } else {
                            this.$toast(res.data.message)
                            this.getInfo()
                        }
                    }).catch(err => {
                        this.$toast(err)
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        //付款完成
        payFinish() {
            Dialog.confirm({
                title: '付款',
                message: '您确定付款完成了吗?',
            })
                .then(() => {
                    this.$http.post(this.host + '/otc/order/pay', { orderSn: this.$route.query.orderNumber }).then(res => {
                        if (res.data.code == 500) {
                            this.$toast(res.data.message)
                        } else {
                            this.$toast(res.data.message)
                            this.getInfo()
                        }
                    }).catch(err => {
                        this.$toast(err)
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        goTell() {
            var nowTime = new Date().getTime();
            var payTime = new Date(this.info.payTime).getTime();
            if (parseInt((nowTime - payTime) / 1000) < 1800) {
                this.$toast('付款完成30分钟后才能申诉')
            } else {
                this.$router.push({
                    path: "/otcrepresentation",
                    query: {
                        num: this.info.orderSn
                    }
                })
            }
        },
        calculateCountdown() {
            const now = Math.floor(Date.now() / 1000);
            const fifteenMinutes = 15 * 60;
            const timestampPlus15Minutes = new Date(this.info.createTime).getTime() / 1000 + fifteenMinutes;
            const timeRemaining = timestampPlus15Minutes - now;
            if (timeRemaining <= 0) {
                this.countdown = {
                    minutes: 0,
                    seconds: 0
                };
                clearInterval(this.intervalId)
                this.$http.post(this.host + '/otc/order/cancel', { orderSn: this.$route.query.orderNumber }).then(res => {
                    if (res.data.code == 500) {
                    } else {
                        this.getInfo()
                    }
                }).catch(err => {
                    this.$toast(err)
                })
                return;
            }
            const minutes = Math.floor((timeRemaining % 3600) / 60);
            const seconds = Math.floor(timeRemaining % 60);
            this.countdown = {
                minutes,
                seconds
            };


        }
    }
}
</script>

<style scoped>
.bottomBtn {
    width: 30.5344vw;
    height: 10.1781vw;
    background-color: #3861FB;
    border-radius: 1.5267vw;
    color: #fff;
    text-align: center;
    line-height: 10.1781vw;
    letter-spacing: .2545vw;
}

.bottom {
    width: 100%;
    height: 12.7226vw;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;

}

.pay1 {
    width: 92.3664vw;
    height: 45.8015vw;
    background-color: #fff;
    border-radius: 1.5267vw;
    margin: 0 auto;
    margin-top: 3.8168vw;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
}

.pay {
    width: 92.3664vw;
    height: 20.3562vw;
    background-color: #fff;
    border-radius: 1.5267vw;
    margin: 0 auto;
    margin-top: 3.8168vw;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
}

.contentList span:first-child {
    font-family: SF Pro;
    font-size: 3.5623vw;
    font-weight: bold;
    color: #616E85;
}

.contentList span:last-child {
    font-family: SF Pro;
    font-size: 3.5623vw;
    font-weight: 510;
    color: #0D1421;
}

.contentList {
    width: 82.1883vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5.0891vw;
}

.status {
    width: 21.883vw;
    height: 7.6336vw;
    border-radius: 1.0178vw;
    background: rgba(234, 57, 67, 0.2);
    font-family: SF Pro;
    font-size: 3.5623vw;
    font-weight: 510;
    color: #EA3943;
    text-align: center;
    line-height: 7.6336vw
}

.contentTitle span {
    font-family: SF Pro;
    font-size: 5.0891vw;
    font-weight: 590;
}

.contentTitle {
    width: 82.1883vw;
    height: 12.7226vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: .2545vw solid #EFF0F2;
    margin: 0 auto
}

.content {
    width: 92.3664vw;
    height: 79.3893vw;
    background-color: #fff;
    border-radius: 2.5445vw;
    margin: 0 auto;
    margin-top: -7.6336vw;
    position: relative;
    z-index: 10;
}

.logo div:first-child {
    font-family: SF Pro;
    font-size: 5.0891vw;
    font-weight: 590;
    color: #0D1421;
    margin-top: 6.3613vw;
    margin-left: 11.4504vw;
    letter-spacing: .2545vw;
}

.logo div:nth-child(2) {
    font-family: SF Pro;
    font-size: 5.0891vw;
    font-weight: 590;
    color: #3861FB;
    margin-top: 2.0356vw;
    margin-left: 7.6336vw;
    letter-spacing: .2545vw;
}

.logo div:nth-child(3) {
    font-family: SF Pro;
    font-size: 5.0891vw;
    font-weight: 590;
    color: #0D1421;
    margin-top: 2.0356vw;
    margin-left: 5.0891vw;
    letter-spacing: .2545vw;
}

.logo div:nth-child(4) {
    font-family: SF Pro;
    font-size: 3.8168vw;
    color: #0D1421;
    margin-top: 12.7226vw;
    margin-left: 5.0891vw;
    letter-spacing: .2545vw;
}

.logo {
    width: 100%;
    height: 61.0687vw;
    background: url("../../assets/img/detail.png") no-repeat;
    background-size: 100%;
    overflow: hidden;
    position: relative;
}

::v-deep .van-field__control {
    height: 7.6336vw;
    border: .2545vw solid #3861FB;
}

::v-deep .van-icon {
    color: #000
}

::v-deep .van-cell {
    height: 12.7226vw !important;
}

::v-deep .van-cell__title {
    color: #3861FB !important;
}

::v-deep .van-cell__value {
    color: #3861FB !important;
}
</style>